import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css'

const HeaderMain = ({ data }) => {
    const site = data.allGhostSettings.edges[0].node

    // The debounce function receives our function as a parameter
    // const debounce = (fn) => {
    //     // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    //     let frame;
  
    //     // The debounce function returns a new function that can receive a variable number of arguments
    //     return (...params) => {
    //         // If the frame variable has been defined, clear it now, and queue for next frame
    //         if (frame) { 
    //             cancelAnimationFrame(frame);
    //         }
  
    //         // Queue our function call for the next frame
    //         frame = requestAnimationFrame(() => {
    //             // Call our function and pass any params we received
    //             fn(...params);
    //         });
    //     } 
    // }
    // // Reads out the scroll position and stores it in the data attribute
    // // so we can use it in our stylesheets
    // const storeScroll = () => {
    //     document.documentElement.dataset.scroll = window.scrollY;
    // }
    // if (typeof window === `undefined` || !window.document) {
    //     return;
    // }
  
    // // Listen for new scroll events, here we debounce our `storeScroll` function
    // document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  
    // // Update scroll position for first time
    // storeScroll();

    return (
        <>
            <header className="site-head">
                <section className="container">
                    <div className="site-mast">
                        <div className="site-mast-left">
                            <Link to="/">
                                {site.logo ?
                                    <img className="site-logo" src={site.logo} alt={site.title} />
                                    : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                }
                            </Link>
                        </div>
                        <nav className="site-nav site-mast-right">
                            <div className="site-nav-left">
                                {/* The navigation items as setup in Ghost */}
                                <Navigation data={site.navigation} navClass="site-nav-item" />
                            </div>
                            <div className="site-nav-right">
                                <Link className="site-nav-button button-primary-colors button-size-medium" to="/about">Schedule Your Free Consultation Today</Link>
                            </div>
                        </nav>
                    </div>
                </section>
            </header>
        </>
    )
}

HeaderMain.defaultProps = {
    navClass: `site-nav-item`,
}

HeaderMain.propTypes = {
    data: PropTypes.shape().isRequired,
    navClass: PropTypes.string,
}

export default HeaderMain
